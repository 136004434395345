import React from 'react'
import Layout from '../../components/Layout'
import Img from "gatsby-image";
import {graphql, StaticQuery} from "gatsby";
import { Link } from 'gatsby';

const ThankYouPage = ({ data }) => (
  <Layout>
    <div id="main" className="container">
      <header className="major">
        <h2>Thank you</h2>
      </header>

      <section id="content">
        <span className="image fit">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="Thank you" />
        </span>
        <h3>Thank you for your interest!</h3>
        <p>We will get back to you shortly. <Link to="/">Back to homepage</Link></p>
      </section>

    </div>
  </Layout>
);

export default () => (
    <StaticQuery
        query={graphql`
          query ThankYouPageQuery {
            allFile (filter:{
                relativePath: {eq: "thanks.jpg"}
              }){
              edges {
                node {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => <ThankYouPage data={data} />}
    />
);